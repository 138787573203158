import React, { useRef, useState } from 'react';
import {
  linkAccountClassNames,
  linkAccountInactiveClassNames
} from 'components/organisms/account/Navigation';

import { Link } from 'gatsby';
import LogOutIcon from '@carbon/icons-react/es/logout/24';
import { MAIN_NAVIGATION_ROUTER_KEY } from 'lib/globalConfig';
import MenuLinkContent from 'components/molecules/nav/MenuLinkContent';
import ReceiptIcon from '@carbon/icons-react/es/receipt/24';
import UserIcon from '@carbon/icons-react/es/user/24';
import UserInitial from 'components/molecules/account/userInitial';
import { makeClassNames } from 'lib/util';
import { useRoutePath } from 'state/context/nav';
import { topNavTransitionDuration } from 'styles/classNames/desktopNav';
import PopOverContent from 'components/atoms/animations/PopOverContent';
import {
  activeNavLinkStyles,
  desktopSecondaryPopOverStyles
} from '../../styles';
import { useLogOut } from 'service/hooks/session';

const containerWidth = 'w-rhythm6';

const AccountButton: React.FC<{ light?: boolean }> = ({ light = false }) => {
  const accountRoute = useRoutePath(MAIN_NAVIGATION_ROUTER_KEY.ACCOUNT);
  const orderHistoryRoute = useRoutePath(
    MAIN_NAVIGATION_ROUTER_KEY.ORDER_HISTORY
  );
  const { mutate: logOut } = useLogOut();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [popOverVisible, setPopOverVisible] = useState(false);

  const accountLinks = [
    {
      name: 'My Account',
      href: accountRoute.path,
      icon: UserIcon
    },
    {
      name: 'My Orders',
      href: orderHistoryRoute.path,
      icon: ReceiptIcon
    }
  ];

  const handleLogOut: React.MouseEventHandler<HTMLButtonElement> = e => {
    e.preventDefault();
    logOut({});
  };

  return (
    <div className="relative h-full">
      <button
        ref={buttonRef}
        className={makeClassNames(
          containerWidth,
          'relative flex h-full items-center justify-center',
          activeNavLinkStyles(popOverVisible)
        )}
      >
        <UserInitial
          className={makeClassNames(
            'border transition-colors',
            topNavTransitionDuration,
            light ? 'border-primary' : 'border-primary-200'
          )}
        />
      </button>

      <PopOverContent
        buttonRef={buttonRef}
        className={makeClassNames('w-48', desktopSecondaryPopOverStyles)}
        // NOTE: Shorter duration to account for account button transition
        // See: https://app.clickup.com/t/863gha9gk
        leaveDuration="duration-150"
        onOpen={() => setPopOverVisible(true)}
        onClose={() => setPopOverVisible(false)}
      >
        {accountLinks.map(item => (
          <Link
            key={item.name}
            to={item.href}
            className={makeClassNames(
              linkAccountClassNames,
              linkAccountInactiveClassNames
            )}
          >
            <MenuLinkContent Icon={item.icon} name={item.name} />
          </Link>
        ))}
        <button
          onClick={handleLogOut}
          className={makeClassNames(
            linkAccountClassNames,
            linkAccountInactiveClassNames
          )}
        >
          <MenuLinkContent Icon={LogOutIcon} name="Log Out" />
        </button>
      </PopOverContent>
    </div>
  );
};

export default AccountButton;
